import React from "react"

import view from "./jumbotron.module.scss"

export default function Jumbotron(props) {
  const { jumbotronImage, jumbotronHead } = props.data

  return (
    <section className={view["jumbo-wrap"]}>
      <img alt="Header Image" className={view["jumbo-bg"]} src={jumbotronImage} />
      <div className={view["jumbo-head-wrap"]}>
        <h1 className={view["jumbo-head"]}>{jumbotronHead}</h1>
      </div>
    </section>
  )
}