import React, { useEffect, useContext } from "react"
import FindDealerMobileHome from "../../components/findDealer/findDealerMobileHome"
import viewWrappedFindDealer from "../../components/findDealer/wrappedFindDealer.module.scss"

export default function WrappedFindDealerMobile({ pageContext, pageContent, dealerInfo }) {
  return (
    <section className={viewWrappedFindDealer["find-wrapped"]}>
      <FindDealerMobileHome key={"DealerLocatorMobile"}
        data={{ ...pageContent.DealerLocator }} dealerInfo={{ ...dealerInfo }}
        dealerLocals={pageContext.dealers} googleAPIkey={pageContext.googleAPIkey} />
    </section>
  )
}
